import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'gatsby-theme-dettol/src/components/common/DangerouslySetInnerHtml';
import IconCustom from 'gatsby-theme-dettol/src/components/common/IconCustom';

import getDropDownItem from 'gatsby-theme-dettol/src/utils/dropDownControl';
import { ISafetyInstructionsList } from 'gatsby-theme-dettol/src/components/SafetyInstructions/SafetyInstructionsList/models';

import 'gatsby-theme-dettol/src/components/SafetyInstructions/SafetyInstructionsList/SafetyInstructionsList.scss';
import './SafetyInstructionsListOverride.scss';

const SafetyInstructionsList: FC<ISafetyInstructionsList> = ({ data, id }) => (
  <ul id={id} className="dt-safety-instructions__list">
    {data.map(({ properties: { iconName, description } }) => (
      <li key={`safety-instructions-${iconName}`}>
        <IconCustom icon={getDropDownItem(iconName)} />
        <DangerouslySetInnerHtml html={description} />
      </li>
    ))}
  </ul>
);

export default SafetyInstructionsList;
